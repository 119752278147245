import React from "react"
import TwoColumnLayout from "../components/twoColumnLayout"
import Seo from "../components/seo"
import SocialFollow from "../components/socialFollow"
import styled from "styled-components";
import MEDIA from '../helpers/mediaTemplates';


const TextContainer = styled.div`
position: fixed;
top: 0;
bottom: 0;
max-width: 533px;
z-index: 1000;
width: 33%;
overflow-y: auto;
-ms-overflow-style: none;
scrollbar-width: none;
padding-right: 2rem;
padding-top: 2rem;
&::-webkit-scrollbar {
    display: none;
}
${MEDIA.TABLET`
    width: 100%;
    position: static;
    align-items: left;
    padding: 2rem;
    padding-bottom: 0;
    z-index: 10;
  `};
${MEDIA.PHONE`
  width: 100%;
  position: static;
  align-items: left;
  padding: 2rem;
  padding-bottom: 0;
  z-index: 10;
  `};
`

const Header = styled.h2`
  // font-size: 40px;
${MEDIA.TABLET`
  `};
${MEDIA.PHONE`
  `};
`
const IndexPage = () => {

  return(
         <TwoColumnLayout Column1Size="66" Column1Content={
          <div>
            <a href={"/How-to-Move-a-Zoo/"}>
              <img className='homeThumbnails' src={'../images/HomeImages/HTMAZ.jpg'} title={'How to Move a Zoo'} alt={'How to Move a Zoo'}/>
            </a>
            <a href={"/Different-Worlds/"}>
              <img className='homeThumbnails' src={'../images/HomeImages/DifferentWorlds.png'} title={'Different Worlds'} alt={'Different Worlds'}/>
            </a>
            <a href={"/Layered-Horizons/"}>
              <img className='homeThumbnails' src={'../images/HomeImages/layeredHorizons.jpg'} title={'Layered Horizons'} alt={'Layered Horizons'}/>
            </a>
            <a href={"/NGA/"}>
              <img className='homeThumbnails' src={'../images/HomeImages/NGA.jpg'} title={'NGA'} alt={'NGA'}/>
            </a>
            <a href={"/The-Bloom-Around-Us/"}>
              <img className='homeThumbnails' src={'../images/HomeImages/TBAU.jpg'} title={'The Bloom Around Us'} alt={'The Bloom Around Us'}/>
            </a>
            <a href={"/RNA-UTS/"}>
              <img className='homeThumbnails' src={'../images/HomeImages/sqImage_UTSRNA.jpg'} title={'RNA x UTS'} alt={'RNA x UTS'}/>
            </a>
            <a href={"/UMAI-Dried-Foods/"}>
              <img className='homeThumbnails' src={'../images/HomeImages/UMAI.jpg'} title={'Umai Dried Foods'} alt={'Umai Dried Foods'}/>
            </a>
            <a href={"/Life-on-TRAPPIST/"}>
              <img className='homeThumbnails' src={'../images/HomeImages/Trappist.jpg'} title={'Life on TRAPPIST?'} alt={'Life on TRAPPIST?'}/>
            </a>
            <a href={"/Kowloon-Walled-City/"}>
              <img className='homeThumbnails' src={'../images/HomeImages/KWC.jpg'} title={'Kowloon Walled City'} alt={'Kowloon Walled City'}/>
            </a>

            {/* {homeThumbnails.map(({ id, src, title, href}) =>
              <a key={id} href={href}>
                <img className='homeThumbnails' src={src} key={id} title={title} alt={title}/>
              </a>
            )} */}
          </div>
         }
         Column2Size="33"
         Column2Content={
           <TextContainer>
            <Seo title="Home" />
            <Header>
              Jake Mu is an experience designer & creative technologist. A navigator through design and development, he brings an eclectic mix of skills to realise ideas from beginning to end. 
              <br /><br />
              Most recently, Jake has been crafting immersive and digital projects at Grumpy Sailor. Previously, he has been across the scope of design and development picking up experiences that have taken him from developing VR projects to designing for the media publication, Hypebeast in New York City. 
              <br /><br />
              Currently, Jake is a freelance designer and creative technologist aspiring to create beautiful and meaningful experiences.            </Header>
              <h1>
                <a href="jake-mu_resume.pdf" className="blackText">Resume</a>
                <br /><br />
                Experience Design  || Graphic Design  ||  Creative Code  ||  Data Visualisation  ||  UI/UX  ||  Front End Development  ||  Artist
              </h1>
            <SocialFollow/>
           </TextContainer>

         }
         />
  )




}


export default IndexPage
